import React from 'react'

const Logo=() =>{
    return (
        <div className="flex items-center">
            <img src={require('@/assets/images/logo.png').default} className="w60 rad14" alt="" />
        </div>
    )
}

export default Logo

