
let config={
    // appName:'FunStory',
    // // appLogo:require('@/assets/images/logo.png').default,
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1599044136',
    // contactEmail:'funstory.cs@outlook.com',
    // copyright:'武汉星网汇科技有限公司',
    // title:'FunStory',
    // websiteUrl:'funstorys.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1599044136',
    //     'appstore:bundle_id':'swbro.BookManager',
    //     'appstore:store_id':'1599044136'
    // }


    appName:'Mobooks',
    googlePlayUrl:null,
    appStoreUrl:'https://apps.apple.com/za/app/id1615497181',
    contactEmail:'mobooks.cs@outlook.com',
    copyright:'Mobooks',
    title:'Mobooks',
    websiteUrl:'shepherdnovels.com',
    headMeta:{
        'appstore:developer_url':'https://apps.apple.com/za/app/id1615497181',
        'appstore:bundle_id':'com.mobooks.novel',
        'appstore:store_id':'1615497181'
    }
}
export default config;
