import React from 'react'
import { Link } from 'react-router-dom'
import config from "@/utils/config";
const Footer = () => {
    return (
        <div className="window-width pl60 pr60 pad sizing absolute bottom50 left0 flex items-end justify-between">
            <div className="top-link fz22 lh30 fw400">
                <a href={`mailto:${config.contactEmail}`} className="text-white hover-primary mr90 opa7">Contact Us</a>
                <Link to="/privacy" className="text-white hover-primary mr90 opa7">Privacy Policy</Link>
                <Link to="/termsOfService" className="text-white hover-primary opa7">Terms of Service</Link>
            </div>
            <div className="copyright fz22 lh30 text-white fw400">
                Copyright © 2022 {config.copyright} All Rights Reserved.
            </div>
        </div>
    )
}
export default Footer;
